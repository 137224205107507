import React, { Component } from 'react'
import styled from '@emotion/styled'
import LogoNav from 'src/components/LogoNav'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Button from 'src/components/Button'
import ConditionalRender from 'src/components/ConditionalRender'
import ContentfulRichText from 'src/components/ContentfulRichText'
import TextLockup from 'src/components/TextLockup'
import ThemeSelector from 'src/components/ThemeSelector'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import withSizes from 'react-sizes'
import { colors, typography, animations, mq, util } from 'src/styles'
import MobileDetect from 'mobile-detect'
import Video from 'src/components/Video'
import Parallax from 'src/components/Parallax'
import ProjectATF from 'src/components/ProjectATF'
import ScrollListener from 'src/components/ScrollListener'
import { MdPlayArrow, MdArrowDownward } from 'react-icons/md'

const Wrapper = styled(ThemeSelector)`
	position: relative;
	${ ({ media }) => media && `
		background: ${ colors.black };
		color: ${ colors.bgColor };
	` }
	${ ({ settheme }) => settheme === 'blue' && `
		z-index: 11;
	` }
`

const Eyebrow = styled.h6`
	margin-bottom: 1.75em;
`

const AlignmentContainer = styled.div`
	display: flex;
	align-items: ${ ({ vAlignment }) => vAlignment };
	${ ({ fullHeight, winHeight, showArrow, index }) => fullHeight ? `
		min-height: ${ winHeight };
		padding-top: calc(100vw/12);
		padding-bottom: calc(100vw/12);
		${ mq.largerAndUp } {
			padding-bottom: calc(100vw/28);
		}
	` : `
		min-height: 70vh;
		padding-top: 105px;
		// padding-bottom: 95px;
	` };
	${ mq.largerAndUp } {
		padding-top: calc(100vw/28);
	}
`

const Content = styled.div`
	width: 100%;
	text-align: ${ ({ hAlignment }) => hAlignment };
	${ ({ index }) => index === 0 ? `
		${ util.responsiveStyles('margin-bottom', 80, 60, 50, 40) }
	` : `
		margin-bottom: 0;
	`};
`

const Block = styled.div`
	display: block;
	width: 100%;
	position: relative;

	${ ({ background }) => background && `
		position: absolute;
		height: 100%;
		overflow: hidden;
		z-index: 1;
		bottom: ${ ({ fullHeight }) => fullHeight ? `60px` : `0` };
	` }

	${ ({ content, fullHeight, index }) => content && `
		z-index: 3;
	` }
`

const BgImage = styled(Image)`
	height: 100%;
`

const ATFDownArrow = styled.div`
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const DownArrow = styled.div`
	animation: ${ animations.bounceMinor } 2s infinite;
	text-align: ${ ({ alignment }) => alignment };
	line-height: 1;
	svg {
		display: inline-block;
		vertical-align: top;
		* {
			fill: currentColor;
		}
	}
`

const Overlay = styled.div`
	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
	opacity: .4;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 30%;
	max-height: 300px;
	min-height: 100px;
	z-index: 3;
`

const VideoContainer = styled.div`
	transition: opacity ${ animations.mediumSpeed } ease-in-out;
	position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
	z-index: -1;
	pointer-events: none;

	> div > div span {
		display: none;
	}

`

const VideoStyled = styled(Video)`
	z-index: -10;
	.rh5v-DefaultPlayer_controls {
    position: absolute;
		bottom: 0;
		display: none;
		visibility: hidden;
    right: 0;
    left: 0;
    height: 0;
	}
`

const Divider = styled.div`
	display: block;
	margin: 1.25em 0;
	${ ({ position }) => position === 'lower' && `margin-top: .75em;` }
	* {
		fill: currentColor;
	}
`

// G&W Site Specific Style

const InvertedLogoNav = styled(LogoNav)`
	mix-blend-mode: none;
`

const InvertedNavWrapper = styled.div`
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: calc(100vw/14);
	height: calc(100vw/14);
	clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
	${ mq.largerAndBelow } {
		width: calc(100vw/7 + 42px);
		height: calc(100vw/7 + 42px);
	}
`

const LargeHeadline = styled(TextLockup)`
	h1 {
		margin-bottom: -0.3em;
		${ ({ headlineSize }) => headlineSize === 'h1' ? `
			max-width: 7em !important;
		` : `` }
		${ ({ headlineSize }) => headlineSize === 'h2' ? `
			max-width: 12em !important;
		` : `` }
		${ mq.largerAndUp } {
			margin-bottom: -0.3em;
			transition: margin ${ animations.slowSpeed } ease-in-out, transform ${ animations.mediumSpeed } ease-in-out;
			display: inline-block;

			${ ({ scrolled, index }) => index === 0 ? `
				transform: translate3d(2vw, 0, 0);
				${ scrolled ? `
					transform: translate3d(7.142vw, 0, 0);
				` : `` }
			` : `
				margin-left: 2vw;
			` }
		}
	}
`

const InfoHeader = styled(Grid)`
	top: ${ 100/14 }vw;
	position: absolute;
	left: 0;
	right: 0;
	${ mq.largerAndUp } {
		${ util.responsiveStyles('margin-top', -28, -21, -18, -18) }
		top: ${ 100/28 }vw;
	}
`

const InfoHeaderContent = styled.div`
	${ util.responsiveStyles('height', 56, 42, 36, 36) }
	display: flex;
	align-items: center;
	justify-content: flex-start;
	p {
		margin: 0;
	}
`

const BottomBar = styled.div`
	padding: 20px;
	background: ${ colors.blue };
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
	${ util.responsiveStyles('height', 80, 60, 50, 40) }
	transition: transform ${ animations.mediumSpeed } ease-in-out;
	transform: ${ ({ scrolled }) => scrolled ? `translate3d(0, 100%, 0)` : `none` };
`

const ATFParallax = styled.div`
	display: block;
	width: 100%;
`

const ProjectAtfContent = styled.div`
	${ util.responsiveStyles('padding-top', 250, 180, 170, 100) }
	${ util.responsiveStyles('padding-bottom', 150, 85, 60, 40) }
`

const AtfText = styled(TextLockup)`
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: flex;
			&:before {
				display: inline-block;
				vertical-align: center;
				margin-top: -.1em;
				content: '— '
			}
		}
		p {
			${ typography.bodySmall }
			margin: 0;
		}
	}
`

class ATF extends Component {
	constructor (props) {
		super(props)
		this.state = {
			mobile: false,
		}
	}

	shouldComponentUpdate (prevProps, prevState) {
		const md = new MobileDetect(window.navigator.userAgent)
		if (md.os() === 'iOS' && prevProps.winHeight !== this.props.winHeight || this.props.index !== 0) {
			return false
		}

		return true
	}

	render () {
		const {
			headline,
			headlineSize,
			text,
			image,
			small,
			medium,
			large,
			video,
			textAlignment,
			hAlignment,
			vAlignment,
			fullHeight,
			buttons,
			winHeight,
			winWidth,
			eyebrow,
			showArrow,
			index,
			theme,
			headerArea1,
			headerArea2,
			type,
			tagline,
			children
		} = this.props

		let mobile = false
		
		if (typeof window !== `undefined`) {
			const md = new MobileDetect(window.navigator.userAgent)
			if (md.os() === 'iOS') {
				mobile = true
			}
		}

		const vAlignOptions = {
			bottom: 'flex-end',
			top: 'flex-start',
			baseline: 'baseline',
			center: 'center'
		}

		const verticalAligment = vAlignOptions[vAlignment]

		if (type === 'projectAtf') {
			return (
				<ProjectATF {...this.props}/>
			)
		}

		let windowHeight = '100vh'

		if (mobile && this.props.index === 0) {
			windowHeight = winHeight + 'px'
		}

		return (
			<Wrapper settheme={theme} media={image || video} transitionBg={false}>
				<ConditionalRender condition={theme === 'blue'}>
					<InvertedNavWrapper>
						<InvertedLogoNav />
					</InvertedNavWrapper>
				</ConditionalRender>
				<Block background winHeight={windowHeight} fullHeight={fullHeight}>
					<ConditionalRender condition={video}>
						<VideoContainer>
							<VideoStyled
								cover={true}
								loop={true}
								playing={true}
								muted={true}
								controls={['PlayPause']}
								url={video && video.file.url}
							/>
						</VideoContainer>
					</ConditionalRender>
					{!video && image || small ? (
						<BgImage
							image={image.image}
							small={image.small}
							medium={image.medium}
							large={image.large}
						/>
					) : false}
					{index === 0 && video && image ? <Overlay /> : false}
				</Block>
				<Block content="true" winHeight={windowHeight} fullHeight={fullHeight}>
					<InfoHeader
						small="3 [10] 1"
						medium="10 [3] 1"
						extraLarge="21 [5] 2"
						rowGap="20px"
					>
						<InfoHeaderContent>
							{headerArea2 && (
								<TextLockup alignment="left" text={headerArea2} transition={false}/>
							)}
						</InfoHeaderContent>
					</InfoHeader>
					<AlignmentContainer vAlignment={verticalAligment} winHeight={windowHeight} fullHeight={fullHeight} showArrow={showArrow}>
						<Content hAlignment={hAlignment} index={index}>
							{/*<ATFParallax opacityStart={3} opacityEnd={-1.5} disabled={index !== 0} threshold="1">*/}
							<ATFParallax opacityStart={1.5} opacityEnd={0} disabled={index !== 0 || !fullHeight} threshold="1">
							<Grid
								small="1 [12] 1"
								large="[20] [7] 1"
								extraLarge="[21] [5] 2"
								vAlign="bottom"
								rowGap="3.5vw"
							>
								<ScrollListener.Consumer>
						      {({ scrolledToTop }) => (
										<LargeHeadline
											transition={false}
											theme="bgColor"
											eyebrow={eyebrow}
											alignment={textAlignment}
											headlineSize={headlineSize}
											headlineElement="h1"
											headline={headline}
											text={false}
											buttons={buttons}
											index={index}
											scrolled={!scrolledToTop}
										/>
									)}
								</ScrollListener.Consumer>
								{text && (
									<div>
										<AtfText
											transition={false}
											theme="bgColor"
											eyebrow={eyebrow}
											alignment={textAlignment}
											headlineSize={headlineSize}
											headlineElement="h1"
											headline={false}
											text={text}
											buttons={buttons}
										/>
										{children}
									</div>
								)}
							</Grid>
							</ATFParallax>
						</Content>
					</AlignmentContainer>
				</Block>

				{index === 0 && type !== 'projectAtf' && fullHeight && 
					<ScrollListener.Consumer>
			      {({ scrolledToTop }) => (
							<BottomBar scrolled={!scrolledToTop}/>
						)}
					</ScrollListener.Consumer>
				}

			</Wrapper>
		)
	}
}

ATF.defaultProps = {
	textAlignment: 'center',
	hAlignment: 'center',
	vAlignment: 'center',
	showArrow: false,
	headlineSize: 'h1',
	theme: 'black',
	index: false
}

const sizesToProps = ({ width, height }) => ({
	winWidth: width,
	winHeight: height
})

export default withSizes(sizesToProps)(ATF)
